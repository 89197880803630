<template>
  <v-snackbar :value="showUpdateNotification" :timeout="-1" multi-line bottom>
    <span class="mr-2"
      >Eine neue Version der Tourenverwaltung ist verfügbar.</span
    >
    <v-btn class="mr-2" outlined @click="updateServiceWorker">Neu Laden</v-btn>
    <v-btn outlined secondary @click="hideUpdateNotification = true"
      >Später</v-btn
    >
  </v-snackbar>
</template>

<script>
import { pwaState } from '../../registerServiceWorker'

export default {
  name: 'UpdateNotification',
  components: {},
  props: {},
  data() {
    return {
      hideUpdateNotification: false,
      pwaState: pwaState,
    }
  },
  computed: {
    showUpdateNotification() {
      return !this.hideUpdateNotification && !!this.pwaState.waitingWorker
    },
  },
  methods: {
    updateServiceWorker() {
      this.pwaState.waitingWorker.postMessage({ action: 'skipWaiting' })
      this.hideUpdateNotification = true
    },
  },
  watch: {
    showUpdateNotification(newVal) {
      console.log(this.$route)
    },
  },
}
</script>


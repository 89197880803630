<template>
  <v-app id="container">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row justify="center" align="center">
          <v-col v-if="dialog" cols="10" sm="8" md="4" lg="3">
            <v-card class="elevation-12">
              <v-card-title class="primary white--text">
                SAC-Tourenverwaltung
                <br />Login
              </v-card-title>
              <v-card-text>
                <v-form
                  v-model="valid"
                  ref="form"
                  @submit.prevent="login"
                  lazy-validation
                >
                  <v-text-field
                    class="mt-2"
                    name="email"
                    ref="email"
                    v-model="email"
                    required
                    label="E-Mail"
                    tabindex="1"
                    type="email"
                    placeholder=" "
                    :rules="emailRules"
                  />
                  <v-text-field
                    name="password"
                    ref="pwd"
                    v-model="password"
                    required
                    label="Passwort"
                    tabindex="2"
                    placeholder=" "
                    :type="pwVisible ? 'text' : 'password'"
                    :rules="pwdRules"
                    :append-icon="pwVisible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="() => (pwVisible = !pwVisible)"
                  />
                  <p v-if="error" class="error--text">{{ error }}</p>
                  <v-btn color="success" block type="submit" :disabled="!valid"
                    >Login</v-btn
                  >
                </v-form>
                <br />
                <router-link :to="{ name: 'PWDResetInit' }"
                  >Passwort vergessen?</router-link
                >
              </v-card-text>
            </v-card>
          </v-col>
          <v-progress-circular
            v-if="!dialog"
            indeterminate
            :size="70"
            class="primary--text"
          />
        </v-row>
      </v-container>
    </v-main>
    <update-notification />
  </v-app>
</template>

<script>
import UpdateNotification from '@/components/pwa/UpdateNotification'
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'Login',
  components: { UpdateNotification },
  data() {
    return {
      email: '',
      password: '',
      error: '',
      dialog: true,
      pwVisible: false,
      emailRules: [
        v => !!v || 'Eine E-Mail Adresse ist erforderlich',
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          'Die E-Mail Adresse ist ungültig',
      ],
      pwdRules: [v => !!v || 'Ein Passwort ist erforderlich'],
      valid: true,
    }
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.dialog = false
        useAuthStore().login({ email: this.email, password: this.password })
          .then(() => {
            const next = this.$route.query.next || '/'
            this.$router.push({ path: next }).catch(() => {
              this.$router.push({ path: '/' })
            })
          })
          .catch((error) => {
            console.log(error)
            this.dialog = true
            const response = error.response
            if (response.status === 500) {
              this.error = 'Server Fehler. Versuche es später nochmals!'
              return
            }
            const message = response.data.messag
            if (message.includes('password incorrect')) {
              this.error = 'Passwort oder E-Mail falsch.'
            } else if (message.includes('not activated')) {
              this.error = 'Dein Login wurde deaktiviert.'
            }
          })
      }
    },
  },
}
</script>

<style scoped>
#container {
  background: url("/img/background1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.card__title {
  display: block;
  text-align: center;
  font-size: 1.4em;
}
</style>

